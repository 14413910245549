import React, { lazy } from "react";
import Login from "../Pages/Login/Login";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";

const AuthRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/resetpassword/:token",
    element: <ResetPassword />,
  }
];

export default AuthRoutes;
