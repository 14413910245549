import React from "react";
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Navigate,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";

import Button from "../../Components/Button/Button";
import InputField from "../../Components/InputField/InputField";
import PageLoader from "../../Components/loaders/PageLoader/PageLoader";
import ResetPasswordPopup from "../../Components/Popups/ResetPasswordPopup/ResetPasswordPopup";
import {
	loginUser,
	logout,
	byPassUserByLMS,
} from "../../redux/authentication/authSlice";
import "./LoginStyle.css";
import Cookies from "js-cookie";
import { useEffect } from "react";
import Image from "../../Components/Image/Image";
import { toast, ToastContainer } from "react-toastify";
import Lottie from "lottie-react";
import SignIn from "../../assets/lottie/SignIn.json";
import chatContact from "../../assets/svg/hoverContact.svg";
import playStore from "../../assets/img/playstore.png"

import "react-toastify/dist/ReactToastify.css";

const Login = () => {
	const formObject = { email: "", password: "" };
	const [formData, setFormData] = useState(formObject);
	const [errors, setErrors] = useState({ email: "", password: "" });
	const [resetPopup, setResetPopup] = useState(false);
	const [type, setType] = useState(true);
	const [lmsToken, setLmsToken] = useSearchParams();
	console.log(lmsToken.get("lmsToken2"));
	const historyAction = window.history.action;
	if (historyAction === "POP") {
		Cookies.remove("token");
	}
	const {
		isAuthSliceFetching,
		isAuthSliceSuccess,
		isAuthSliceError,
		authSliceErrorMessage,
		resetRequired,
		isResetSliceSuccess,
	} = useSelector((state) => state.authentication);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const token = Cookies.get("token");

	useEffect(() => {
		if (isAuthSliceSuccess) {
			navigate("/", { replace: true });
		}
	}, [isAuthSliceSuccess]);
	useEffect(() => {
		if (token) navigate("/", { replace: true });
	}, []);

	useEffect(() => {
		//  Clears all the states in the redux store
		dispatch(logout());

		if (isAuthSliceError) {
			let newErrors = { email: "", password: "" };
			newErrors.password =
				"Your Password might have expired Please Reset it.";
			setErrors(newErrors);
		}
	}, [isAuthSliceError]);

	useEffect(() => {
		if (lmsToken.get("lmsToken")) {
			if (window.innerWidth < 600) {
				window.location = `cowork://login/${lmsToken.get("lmsToken")}`;
				console.log("redirecting");
			} else {
				console.log("not redirecting");
			}

			dispatch(byPassUserByLMS({ token: lmsToken.get("lmsToken") }));
		} else {
			dispatch(logout());
		}
	}, []);

	const resetPopupHandler = () => {
		setResetPopup(true);
	};

	/**Handling formData with single onChange */
	const onChangeFormData = (event) => {
		const { name, value } = event.target;
		setFormData({
			...formData,
			[name]: value,
		});
		setErrors({ email: "", password: "" });
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleSignIn();
		}
	};

	const handleSignIn = () => {
		let newErrors = { email: "", password: "" };

		if (!formData.email) {
			newErrors.email = "Email is required.";
		} else if (!/\S+@\S+/.test(formData.email)) {
			newErrors.email = "Invalid email address.";
		}

		if (!formData.password) {
			newErrors.password = "Password is required.";
		}

		if (newErrors.email || newErrors.password) {
			setErrors(newErrors);
			return;
		}

		dispatch(loginUser(formData));
	};

	const errorToast = () => {
		toast.error(authSliceErrorMessage, {
			position: toast.POSITION.BOTTOM_CENTER,
			autoClose: 2000, // Close the toast after 1 seconds
			hideProgressBar: false, // Display progress bar
			pauseOnHover: true, // Pause the timer when hovering over the toast
		});
	};

	useEffect(() => {
		if (
			authSliceErrorMessage === "Authentication failed, User not found."
		) {
			errorToast();
			setErrors({ email: "", password: "" });
		}
	}, [authSliceErrorMessage]);

	return (
		<>
			<ToastContainer />
			<ResetPasswordPopup
				isActive={resetPopup}
				setResetPopup={setResetPopup}
			/>
			{!token && (
				<div className="authenticationWrapper">
					<div
						className="chatWrap"
						onClick={() => navigate("/contact")}
					>
						<p className="dialogueBox">Contact Us</p>

						<img
							src={chatContact}
							alt=""
						/>
					</div>

					{isAuthSliceFetching && <PageLoader />}

					<Lottie
						animationData={SignIn}
						loop={true}
						className="LottieLogin"
					/>
					<div className="LoginWrapperBox">
						<div className="LoginBoxContent">
							<div className="headinglogin">
								<h2 className="signtext">Sign In</h2>
							</div>

							<div className="InputwrapBox">
								<InputField
									notImp={"notimp"}
									labelName={"Email Id"}
									placeholder={"Enter registered email Id"}
									onChange={onChangeFormData}
									name="email"
									type={"text"}
									isError={errors.email ? true : false}
									erroMessage={errors.email}
									onKeyDown={handleKeyDown}
								/>
								<InputField
									notImp={"notimp"}
									labelName={"Password"}
									placeholder={"Enter Your Password"}
									onChange={onChangeFormData}
									name="password"
									type={type === true ? "password" : "text"}
									isError={errors.password ? true : false}
									erroMessage={errors.password}
									inputIcon={
										type === true ? "eyeSlash" : "blueEye"
									}
									onclick={() => setType(!type)}
									onKeyDown={handleKeyDown}
								/>
							</div>
							<div className="textRight mt-20 resetPasstext">
								<div
									onClick={resetPopupHandler}
									className="resettext"
								>
									Reset Password?
								</div>
							</div>
							<Button
								onClick={handleSignIn}
								className={"BlueFillButton loginButton"}
								name={"Sign in"}
							/>
							<a
								className="androidText"
								href="https://play.google.com/store/apps/details?id=com.mastersunion.cowork"
								target="_blank"
							>
								<img src={playStore} alt="" className="playStoreImage" />
							</a>

							<a class="androidText" style={{marginTop: "10px"}} href="https://play.google.com/store/apps/details?id=com.mastersunion.cowork" target="_blank">Get the android app</a>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Login;
