import React from "react";
import mainlogo from "../../assets/img/mainlogo.svg";
import Cemail from "../../assets/svg/Cemail.svg";
import Pcall from "../../assets/svg/Pcall.svg";
import location from "../../assets/svg/location.svg";

import "../../Pages/Contact/contact.css";
import { useNavigate } from "react-router-dom";

function Contact() {
	const navigate = useNavigate();
	return (
		<main className="contactUsWrapper">
			<div
				className="headerlogo"
				onClick={() => navigate("/")}
			>
				<img
					src={mainlogo}
					alt=""
				/>
			</div>

			<div className="contactusContainer">
				<div className="header">
					<h2>Contact Us</h2>
					<p>
						Uncertain about your needs? Reach out to us; we're here
						to listen and assist you.
					</p>
				</div>

				<div className="contactDetailsWrap">
					<div className="box">
						<img
							src={Cemail}
							alt=""
						/>
						<h4>Email</h4>
						<div className="contentWrap">
							<p>cowork@mastersunion.org</p>
						</div>
					</div>
					<div className="box">
						<img
							src={Pcall}
							alt=""
						/>
						<h4>Phone</h4>
						<div className="contentWrap">
							<p>+91 90416-61525</p>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Contact;
