import React, { lazy } from "react";
import { renderDashboardBasedOnRole } from "../Layouts/MainLayout";

function DynamicDashboard() {
  const dashboardComponent = renderDashboardBasedOnRole();

  return (
    <React.Fragment>
      {dashboardComponent}
    </React.Fragment>
  );
}

export default DynamicDashboard;
