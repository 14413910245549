import React, { useEffect, useState } from "react";
import "./ResetPasswordPopup.css";
import Button from "../../Button/Button";
import InputField from "../../InputField/InputField";
import { toast, ToastContainer } from "react-toastify";
import { coworkData } from "../../../redux/cowork/coworkSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { postResetPasswordEmail,clearCoworkSliceStates } from "../../../redux/cowork/coworkSlice";

const ResetPasswordPopup = ({ isActive, setResetPopup }) => {
  const dispatch = useDispatch();
  const [baseUrl, setBaseUrl] = useState('');

 
  const { isCoworkSliceSmallFetching, isCoworkSliceSuccess,isCoworkSliceError, coworkSliceErrorMessage} =
    useSelector(coworkData);

  const formObject = { email: "",baseUrl:"" };
  const [formData, setFormData] = useState(formObject);
  const [errors, setErrors] = useState({
    email: "",
  });
  const onChangeFormData = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  
  useEffect(() => {
    // console.log(formData)
    // Get the current URL
    const currentUrl = window.location.href;

    // Split the URL by '/'
    const parts = currentUrl.split('/');

    // Get the base URL by joining the parts up to the first slash
    const base = parts.slice(0, 3).join('/');

    // Set the base URL in state
    setBaseUrl(base);
    setFormData({
      ...formData,
      baseUrl:base
    });
  }, []);

  useEffect(() => {
    console.log(baseUrl)
  },[formData])
  
  useEffect(() => {
    if (isCoworkSliceSuccess) {
      toast.success("Email Sent Successfully", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000, // Close the toast after 3 seconds
        hideProgressBar: false, // Display progress bar
      });
    }
    setResetPopup(false);
    return () => {
      dispatch(clearCoworkSliceStates())
    }
  }, [isCoworkSliceSuccess]);
useEffect(() => {
  if(isCoworkSliceError){
    toast.error(coworkSliceErrorMessage, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: false, // Display progress bar
    });
  }
},[isCoworkSliceError])
  const resetPasswordHandler = () => {
    let newErrors = { email: "" };

    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+/.test(formData.email)) {
      newErrors.email = "Invalid email address.";
    } else{
      newErrors.email = "";

    }
    if (newErrors.email ) {
      setErrors(newErrors);
      return;
    }
    // validateEmail(formData.email);
    console.log(formData)
    dispatch(postResetPasswordEmail(formData));
  };

  return (
    isActive && (
      <div className={`popupWrapper ResetpopupWrap ${isActive && "active"}`}>
        <div className="popupContainer">
          <div className="popupHeader">
            <h4 className="popupHeading">Reset Password</h4>
          </div>
          <div className="popupBody ">
            <div className="createRoomContainer">
              <ToastContainer />
              <div className="createRoomBody">
                <InputField
                  notImp={"notimp"}
                  labelName={"Email Id"}
                  placeholder={"Enter registered email Id"}
                  onChange={onChangeFormData}
                  name="email"
                  type={"email"}
                  isError={errors.email ? true : false}
                  erroMessage={errors.email}
                />
              </div>
            </div>
          </div>
          <div className="popupFooter">
            <div className="buttonWrapper">
              <Button
                className={"GreyFillButton"}
                name={"Cancel"}
                onClick={() => {
                  setResetPopup(false);
                }}
              />
              <Button
                className="BlueFillButton"
                name="Send Email"
                onClick={resetPasswordHandler}
                isWaiting={isCoworkSliceSmallFetching}
                disabled={isCoworkSliceSmallFetching}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ResetPasswordPopup;
