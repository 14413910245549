import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
let API = process.env.REACT_APP_API;

const data =
  Cookies.get("loginData") !== "undefined" && Cookies.get("loginData")
    ? JSON.parse(Cookies.get("loginData"))
    : null;
console.log(data);
let orgId;
let userId;
let createdBy;
let officialEmail;

const setTokenValues = () => {
  orgId = data.organizationId;
  userId = data.id;
};

// {
//   "id": "664b8e69-fee9-49a7-9727-b8a288946dd0",
//   "firstName": "Anish",
//   "lastName": "Babbar",
//   "organizationId": "52c3b93c-59ba-44e7-8e99-bd5fc889b68d",
//   "uidNumber": "30914",
//   "userStatus": "Active"
// }

// console.log(data, "createdBy");
// console.log("env",REACT_APP_BASE_URL);

export const loginUser = createAsyncThunk(
  "loginUser",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(`${API}/api/cowork/auth/login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const data = await response.json();
      console.log(response.status, "response", data);
      if (response?.status === 200) {
        Cookies.set("loginData", JSON.stringify(data?.Data));
        if (!data.resetRequired) {
          Cookies.set("token", data?.Token);
        }
        // Cookies.set("userName", data?.Data.firstName);
        Cookies.set("userEmail", data?.Data.username);
        const roles = data?.Roles.map((role) => {
          return role.roleName;
        });
        Cookies.set("roles", JSON.stringify(roles));

        // Convert the array to a JSON string
        const jsonData = JSON.stringify(data?.Roles);

        // Set the JSON string as a cookie
        Cookies.set("myCookie", jsonData);
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



export const byPassUserByLMS = createAsyncThunk(
  "cowork/byPassUserByLMS",
  async (obj, thunkAPI) => {
    console.log("inside thunk")
    try {
      const response = await fetch(
        `${API}/api/cowork/auth/bypassuser`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token:obj.token
          }),
        }
      );
      let data = await response.json();
      if (response?.status === 200) {
        Cookies.set("loginData", JSON.stringify(data?.Data));
        if (!data.resetRequired) {
          Cookies.set("token", data?.Token);
        }
        // Cookies.set("userName", data?.Data.firstName);
        Cookies.set("userEmail", data?.Data.username);
        const roles = data?.Roles.map((role) => {
          return role.roleName;
        });
        Cookies.set("roles", JSON.stringify(roles));

        // Convert the array to a JSON string
        const jsonData = JSON.stringify(data?.Roles);

        // Set the JSON string as a cookie
        Cookies.set("myCookie", jsonData);
        console.log(data, "data");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);


//reset password
export const resetpassword = createAsyncThunk(
  "resetpassword",
  async (obj, thunkAPI) => {
    try {
      const response = await fetch(`${API}/api/cowork/auth/resetpassword/${obj.token}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
      });
      const data = await response.json();
      // console.log(response.status, "response", data);
      if (response?.status === 200) {
        

        console.log(data, "data");
        return data.Data;
      }
    } catch (e) {
      console.log("Error", e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
const initialStateValues = {
  resetRequired:false,
  isAuthSliceFetching: false,
  isAuthSliceSuccess: false,
  isAuthSliceError: false,
  isResetSliceSuccess:false,
  authSliceErrorMessage: "",
};

const authSlice = createSlice({
  name: "authentication",
  initialState: initialStateValues,
  reducers: {
    // omit reducer cases
    logout: (state, action) => {
      return initialStateValues;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      console.log(payload);
      //   state.isLoggedIn = true;
      state.isAuthSliceSuccess = true;
      state.resetRequired = payload?.resetRequired;
      //   state.token = payload.token.access.token;
      return state;
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceError = true;
      state.authSliceErrorMessage = payload?.Message;
      return state;
    });
    builder.addCase(loginUser.pending, (state, { payload }) => {
      state.isAuthSliceFetching = true;
      return state;
    });

    builder.addCase(byPassUserByLMS.fulfilled, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceSuccess = true;
      state.resetRequired = false;
      state.isResetSliceSuccess = true;
      return state;
    });
    builder.addCase(byPassUserByLMS.pending, (state, { payload }) => {
      state.isAuthSliceFetching = true;
      return state;
    });
    builder.addCase(byPassUserByLMS.rejected, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceError = true;
      state.isResetSliceSuccess = false;
      state.authSliceErrorMessage = payload?.Message || "Something Went Wrong";
      return state;
    });


    builder.addCase(resetpassword.fulfilled, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceSuccess = true;
      state.resetRequired = false;
      state.isResetSliceSuccess = true;
      return state;
    });
    builder.addCase(resetpassword.pending, (state, { payload }) => {
      state.isAuthSliceFetching = true;
      return state;
    });
    builder.addCase(resetpassword.rejected, (state, { payload }) => {
      state.isAuthSliceFetching = false;
      state.isAuthSliceError = true;
      state.isResetSliceSuccess = false;
      state.authSliceErrorMessage = payload?.Message || "Something Went Wrong";
      return state;
    });
    
  },
});

export const { logout } = authSlice.actions;
export default authSlice;
