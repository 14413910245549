import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
let userId;
let organizationId;
let token;
let headers;
let API = process.env.REACT_APP_API;

const setTokenValues = () => {
  const data = Cookies.get("loginData")
    ? JSON.parse(Cookies.get("loginData"))
    : null;
  userId = data?.id;
  organizationId = data?.organizationId;
  token = Cookies.get("token");
  headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
};

// Admin Routes
// Create New Room
export const postRoom = createAsyncThunk(
  "cowork/postRoom",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(`${API}/api/cowork/rooms/addRoom`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          room_name: obj.roomName,
          capacity: obj.roomCapacity,
          tower: obj.tower,
          availability: obj.availability,
          room_thumbnail: obj.room_thumbnail,
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get All Bookings
export const getAllBookings = createAsyncThunk(
  "cowork/getAllBookings",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/allbookings/${obj.pageNumber}/${obj.perPageCount}?searchQuery=${obj.searchValue}&tower=${obj.filters.tower}&meetingDate=${obj.filters.meetingDate}&status=${obj.filters.status}&role=${obj.filters.role}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Get All Rooms
export const getAllRooms = createAsyncThunk(
  "cowork/getAllRooms",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/rooms/allrooms/${obj.pageNumber}/${obj.perPageCount}?searchQuery=${obj.searchValue}&tower=${obj.filters.tower}&capacity=${obj.filters.capacity}&availability=${obj.filters.availability}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Update Room Details
export const postUpdateRoom = createAsyncThunk(
  "cowork/postUpdateRoom",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/rooms/updateRoom/${obj.id}`,
        {
          method: "PUT",
          headers,
          body: JSON.stringify({
            room_name: obj.roomName,
            capacity: obj.roomCapacity,
            tower: obj.tower,
            availability: obj.availability,
            room_thumbnail: obj.room_thumbnail,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const postRoomActivity = createAsyncThunk(
  "cowork/postRoomActivity",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/rooms/updateAvailability/${userId}/${obj.id}`,
        {
          method: "PUT",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// USER ROUTES

/**GET ALL Available Rooms  */
export const getAvailableRooms = createAsyncThunk(
  "cowork/getAvailableRooms",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/rooms/fetchAvailableAllRoomsandSlots/${userId}/${obj.pageNumber}/${obj.perPageCount}?meetingDate=${obj.meetingDate}&searchQuery=${obj.searchValue}&tower=${obj.filters.tower}&capacity=${obj.filters.capacity}&isStudent=${obj.isStudent}&isAdmin=${obj.isAdmin}`,
        {
          method: "GET",
          headers,
        }
      );
      
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

// Add A new Booking
export const postRoomBookings = createAsyncThunk(
  "cowork/postRoomBookings",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/bookroom/${userId}/${obj.room_id}`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            date: obj.meetingDate,
            slot: obj.meetingSlot,
            meeting_detail: obj.meetingDetail,
            attendees: obj.selectedAttendees,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getMyBookings = createAsyncThunk(
  "cowork/getMyBookings",
  async (obj, thunkAPI) => {
    console.log(obj);
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/mybookings/${userId}/${obj.pageNumber}/${obj.perPageCount}?searchQuery=${obj.searchValue}&tower=${obj.filters.tower}&status=${obj.filters.status}&eventStatus=${obj.status}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const cancelABooking = createAsyncThunk(
  "cowork/cancelABooking",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      console.log(obj)
      const response = await fetch(
        `${API}/api/cowork/roomBooking/cancelBooking/${obj.id}/${userId}`,
        {
          method: "PUT",
          headers
        }
      );

      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAvailableSlots = createAsyncThunk(
  "cowork/getAvailableSlots",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/fetchSlots/${obj.meetingDate}/${obj.id}/${userId}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAllCoworkUsers = createAsyncThunk(
  "cowork/getAllCoworkUsers",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/allcoworkusers/${userId}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);



export const getBookingAtSameTime = createAsyncThunk(
  "cowork/getBookingAtSameTime",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/findBookingAtSameTime/${obj.roomId}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const findBookingAtSameTimeDownHierarchy = createAsyncThunk(
  "cowork/findBookingAtSameTimeDownHierarchy",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/findBookingAtSameTime/${obj.roomId}/${obj.meetingDate}/${obj.meetingSlot}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const findSlotsForOneRoom = createAsyncThunk(
  "cowork/findSlotsForOneRoom",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/rooms/fetchOneRoomSlots/${userId}/${obj.roomId}?meetingDate=${obj.meetingDate}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const getAdditionalCoworkUsers = createAsyncThunk(
  "cowork/getAdditionalCoworkUsers",
  async (obj, thunkAPI) => {
    try {
      console.log(obj);
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/findNotIncludedAttendees/${userId}/${obj.roomBookingId}`,
        {
          method: "GET",
          headers,
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const addAdditionalCoworkUsers = createAsyncThunk(
  "cowork/addAdditionalCoworkUsers",
  async (obj, thunkAPI) => {
    try {
      console.log(obj);
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/addAttendees/${obj.roomBookingId}`,
        {
          method: "PUT",
          headers,
          body: JSON.stringify({
            attendees: obj.selectedAttendees,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        console.log(data);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const postResetPasswordEmail = createAsyncThunk(
  "cowork/postResetPasswordEmail",
  async (obj, thunkAPI) => {
    try {
      // console.log(obj)
      setTokenValues();
      const response = await fetch(`${API}/api/cowork/auth/resetpasswordurl`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          email:obj.email,
          baseUrl:obj.baseUrl
        }),
      });
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);
export const postRoomBookingAdmin = createAsyncThunk(
  "cowork/postRoomBookingAdmin",
  async (obj, thunkAPI) => {
    try {
      setTokenValues();
      const response = await fetch(
        `${API}/api/cowork/roomBooking/adminbookroom/${userId}/${obj.room_id}`,
        {
          method: "POST",
          headers,
          body: JSON.stringify({
            date: obj.meetingDate,
            slot: obj.meetingSlot,
            meeting_detail: obj.meetingDetail,
            attendees: obj.selectedAttendees,
          }),
        }
      );
      let data = await response.json();
      if (response.status === 200) {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (error) {
      console.log(error, "err");
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const initialStateValues = {
  attendeesAddedSuccessfully:false,
  isCoworkSliceFetching: false,
  isCoworkSliceSmallFetching: false,
  isCoworkSliceFetchingRoomBooking: false,
  isCoworkSliceSuccess: false,
  isCoworkSliceError: false,
  isCoworkSliceUserFetch:false,
  coworkSliceSuccessMessage: "",
  coworkSliceErrorMessage: "",
  roomSelectionDetail: null,
  availableRoomsList: [],
  availableRoomsCount: 0,
  myBookingsList: [],
  myBookingCount: 0,
  bookingCancelledSuccesfully: false,
  bookedSlots: [],
  coworkUsers: [],
  bookingAtSameTimeMessage: "",
  BookingAtSameTimeDownHierarchyMessage: "",
  AllBookingList: [],
  AllBookingCount: 0,
  AllRooms: [],
  AllRoomsCount: 0,
  roomToBeUpdatedDetails: null,
  selectedDate: null,
  roomAlreadyBooked:false,
  selectedSlot: null,
  selectedAttendees:[],
  slotsForOneRoom:[],
  additionalUsers:[],
  selectedAdditionalAttendees:[],
  finalMultipleSlotsForAdmin:[],
  availableRoomMyBookings:[]
};

const coworkSlice = createSlice({
  name: "cowork",
  initialState: initialStateValues,
  reducers: {
    // Reducer Action to clear initial states after success from any AsynCThunk call
    clearCoworkSliceStates: (state, action) => {
      state.isCoworkSliceFetching = false;
        state.isCoworkSliceSmallFetching = false;
        state.isCoworkSliceSuccess = false;
        state.isCoworkSliceError = false;
        state.coworkSliceSuccessMessage = "";
        state.coworkSliceErrorMessage = "";
        state.bookingCancelledSuccesfully = false;
      return state;
    },
    clearCoworkSliceData: (state, action) => {
      state.availableRoomsList = [];
      state.myBookingsList = [];
      state.AllBookingList = [];
      state.slotsForOneRoom = [];
      state.AllRooms = [];
      state.selectedAttendees = [];
      state.availableRoomsCount = 0;
      state.myBookingCount = 0;
      state.AllBookingCount = 0;
      state.AllRoomsCount = 0;
      return state;
    },
    clearRoomDetail: (state, action) => {
      state.roomSelectionDetail = null;
      return state;
    },
    addRoomDetails: (state, action) => {
      state.roomSelectionDetail = action.payload;
      return state;
    },
    addRoomDetailsToBeUpdated: (state, action) => {
      state.roomToBeUpdatedDetails = action.payload;
      return state;
    },
    addSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
      return state;
    },

    addSelectedSlot: (state, action) => {
      state.selectedSlot = action.payload;
      return state;
    },
    addRoomStatus: (state, action) => {
      state.roomAlreadyBooked = action.payload;
      return state;
    },
    clearSelectedSlot: (state, action) => {
      state.selectedSlot = null;
      return state;
    },
    addSelectedAttendees: (state, action) => {
      state.selectedAttendees = action.payload;
      return state;
    },
    clearSelectedAttendees: (state, action) => {
      state.selectedAttendees = [];
      return state;
    },
    addAdditionSelectedAttendees: (state, action) => {
      console.log(action.payload);
      state.selectedAdditionalAttendees = action.payload;
      return state;
    },
    clearAdditionalSelectedAttendees: (state, action) => {
      state.selectedAdditionalAttendees = [];
      return state;
    },
    addFinalMultipleSlotsForAdmin: (state, action) => {
      state.finalMultipleSlotsForAdmin = action.payload;
      return state;
    },
    clearFinalMultipleSlotsForAdmin: (state, action) => {
      state.finalMultipleSlotsForAdmin = [];
      return state;
    },
  },
  extraReducers: (builder) => {
    // CASES FOR GET COHORT FEE
    builder.addCase(getAvailableRooms.fulfilled, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceSmallFetching = false;
      console.log(payload);
      state.availableRoomsList = [
        ...state.availableRoomsList,
        ...payload?.roomAndSlots,
      ];
      state.availableRoomMyBookings = payload?.myBookings;
      state.availableRoomsCount = payload.roomAndSlotsCount;
      return state;
    });
    builder.addCase(getAvailableRooms.rejected, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceSmallFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(getAvailableRooms.pending, (state, { payload }) => {
      state.isCoworkSliceFetching = true;
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
    builder.addCase(postRoomBookings.fulfilled, (state, { payload }) => {
      state.isCoworkSliceFetchingRoomBooking = false;
      state.isCoworkSliceSuccess = true;
      state.coworkSliceSuccessMessage = "Room Booked Successfully";
      return state;
    });
    builder.addCase(postRoomBookings.rejected, (state, { payload }) => {
      state.isCoworkSliceFetchingRoomBooking = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(postRoomBookings.pending, (state, { payload }) => {
      state.isCoworkSliceFetchingRoomBooking = true;
      return state;
    });
    builder.addCase(getMyBookings.fulfilled, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceSmallFetching = false;
      state.myBookingsList = [...state.myBookingsList, ...payload.bookings];
      state.myBookingCount = payload.myBookingsCount;
      return state;
    });
    builder.addCase(getMyBookings.rejected, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceSmallFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(getMyBookings.pending, (state, { payload }) => {
      state.isCoworkSliceFetching = true;
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
    builder.addCase(cancelABooking.fulfilled, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = false;
      state.isCoworkSliceSuccess = true;
      console.log(payload?.updatedRoom);
      let updatedMyBookingIndex = state.myBookingsList.findIndex(
        (data) => data.id === payload?.updatedRoom.id
      );
      let updatedAllBookingIndex = state.AllBookingList.findIndex(
        (data) => data.id === payload?.updatedRoom.id
      );

      console.log(updatedMyBookingIndex, updatedAllBookingIndex);
      if(updatedMyBookingIndex != -1){ 
        state.myBookingsList[updatedMyBookingIndex] = payload?.updatedRoom;
      }else if(updatedAllBookingIndex != -1){ 
        state.AllBookingList[updatedAllBookingIndex] = payload?.updatedRoom;
      }

      state.coworkSliceSuccessMessage = "Booking Cancelled Successfully";
      return state;
    });
    builder.addCase(cancelABooking.rejected, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(cancelABooking.pending, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
    builder.addCase(getAvailableSlots.fulfilled, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.bookingCancelledSuccesfully = true;
      state.bookedSlots = payload?.fetchedSlotsBooked || [];
      return state;
    });
    builder.addCase(getAvailableSlots.rejected, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(getAvailableSlots.pending, (state, { payload }) => {
      state.isCoworkSliceFetching = true;
      return state;
    });
    builder.addCase(getAllCoworkUsers.fulfilled, (state, { payload }) => {
      state.isCoworkSliceUserFetch = false;
      state.coworkUsers = payload?.users || [];
      return state;
    });
    builder.addCase(getAllCoworkUsers.rejected, (state, { payload }) => {
      state.isCoworkSliceUserFetch = false;
      // state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(getAllCoworkUsers.pending, (state, { payload }) => {
      state.isCoworkSliceUserFetch = true;
      return state;
    });
    builder.addCase(
      findBookingAtSameTimeDownHierarchy.fulfilled,
      (state, { payload }) => {
        state.isCoworkSliceSmallFetching = false;
        state.BookingAtSameTimeDownHierarchyMessage =
          payload?.message || "Are You Sure, You want to book the Room";
        return state;
      }
    );
    builder.addCase(
      findBookingAtSameTimeDownHierarchy.rejected,
      (state, { payload }) => {
        state.isCoworkSliceSmallFetching = false;
        state.isCoworkSliceError = true;
        state.coworkSliceErrorMessage =
          payload?.message || "Something Went Wrong";
        return state;
      }
    );
    builder.addCase(
      findBookingAtSameTimeDownHierarchy.pending,
      (state, { payload }) => {
        state.isCoworkSliceSmallFetching = true;
        return state;
      }
    );
    builder.addCase(postRoom.fulfilled, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceSuccess = true;
      state.coworkSliceSuccessMessage = payload?.message || " ";
      return state;
    });
    builder.addCase(postRoom.rejected, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(postRoom.pending, (state, { payload }) => {
      state.isCoworkSliceFetching = true;
      return state;
    });
    builder.addCase(getAllBookings.fulfilled, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceSmallFetching = false;
      state.coworkSliceSuccessMessage = payload?.message || " ";
      state.AllBookingList = [...state.AllBookingList, ...payload?.bookings];
      state.AllBookingCount = payload?.allBookingsCount;
      return state;
    });
    builder.addCase(getAllBookings.rejected, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceSmallFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(getAllBookings.pending, (state, { payload }) => {
      state.isCoworkSliceFetching = true;
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
    builder.addCase(getAllRooms.fulfilled, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceSmallFetching = false;
      state.coworkSliceSuccessMessage = payload?.message || " ";
      state.AllRooms = [...payload?.rooms, ...state.AllRooms];
      state.AllRoomsCount = payload?.allRoomCount;
      return state;
    });
    builder.addCase(getAllRooms.rejected, (state, { payload }) => {
      state.isCoworkSliceFetching = false;
      state.isCoworkSliceSmallFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(getAllRooms.pending, (state, { payload }) => {
      state.isCoworkSliceFetching = true;
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
    builder.addCase(postUpdateRoom.fulfilled, (state, { payload }) => {
      state.isCoworkSliceSuccess = true;
      state.coworkSliceSuccessMessage = payload?.message || " ";
      return state;
    });
    builder.addCase(postUpdateRoom.rejected, (state, { payload }) => {
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(postUpdateRoom.pending, (state, { payload }) => {
      state.isCoworkSliceFetching = true;
      return state;
    });
    builder.addCase(postRoomActivity.fulfilled, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = false;
      console.log(payload);
      let updatedRoomIndex = state?.AllRooms?.findIndex(
        (data) => data.id === payload?.updatedRoom?.id
      );
      console.log(updatedRoomIndex);
      state.AllRooms[updatedRoomIndex] = payload?.updatedRoom;
      state.isCoworkSliceSuccess = true;
      state.coworkSliceSuccessMessage = payload?.message || " ";
      return state;
    });
    builder.addCase(postRoomActivity.rejected, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(postRoomActivity.pending, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
    builder.addCase(findSlotsForOneRoom.fulfilled, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = false;
      console.log("findSlotsForOneRoom", payload);
      state.slotsForOneRoom = payload?.roomAndSlots;
      state.availableRoomMyBookings = payload?.myBookings;
      // state.isCoworkSliceSuccess = true;
      state.coworkSliceSuccessMessage = payload?.message || " ";
      return state;
    });
    builder.addCase(findSlotsForOneRoom.rejected, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(findSlotsForOneRoom.pending, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
    builder.addCase(
      getAdditionalCoworkUsers.fulfilled,
      (state, { payload }) => {
        state.isCoworkSliceSmallFetching = false;
        console.log(payload.users);
        state.additionalUsers = payload.users;
        state.coworkSliceSuccessMessage = payload?.message || " ";
        return state;
      }
    );
    builder.addCase(getAdditionalCoworkUsers.rejected, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(getAdditionalCoworkUsers.pending, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
    builder.addCase(
      addAdditionalCoworkUsers.fulfilled,
      (state, { payload }) => {
        state.isCoworkSliceSmallFetching = false;
        state.attendeesAddedSuccessfully = true;
        state.additionalUsers = payload.users;
        state.coworkSliceSuccessMessage = payload?.message || " ";
        return state;
      }
    );
    builder.addCase(addAdditionalCoworkUsers.rejected, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = false;
      state.attendeesAddedSuccessfully = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(addAdditionalCoworkUsers.pending, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
    builder.addCase(postRoomBookingAdmin.fulfilled, (state, { payload }) => {
      state.isCoworkSliceFetchingRoomBooking = false;
      state.isCoworkSliceSuccess = true;
      state.coworkSliceSuccessMessage = "Room Booked Successfully";
      return state;
    });
    builder.addCase(postRoomBookingAdmin.rejected, (state, { payload }) => {
      state.isCoworkSliceFetchingRoomBooking = false;
      state.isCoworkSliceError = true;
      state.coworkSliceErrorMessage =
        payload?.message || "Something Went Wrong";
      return state;
    });
    builder.addCase(postRoomBookingAdmin.pending, (state, { payload }) => {
      state.isCoworkSliceFetchingRoomBooking = true;
      return state;
    });
    builder.addCase(postResetPasswordEmail.fulfilled, (state, { payload }) => {
      state.isCoworkSliceSuccess = true;
      state.isCoworkSliceSmallFetching = false;
      state.coworkSliceSuccessMessage = payload?.message || " ";
      return state;
    });
    builder.addCase(postResetPasswordEmail.rejected, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = false;
      state.isCoworkSliceError = true;
      console.log(payload)
      state.coworkSliceErrorMessage =
        payload?.Message || "Something Went Wrong";
      return state;
    });
    builder.addCase(postResetPasswordEmail.pending, (state, { payload }) => {
      state.isCoworkSliceSmallFetching = true;
      return state;
    });
  },
});

export const {
  clearCoworkSliceStates,
  clearCoworkSliceData,
  clearRoomDetail,
  addRoomDetails,
  addRoomDetailsToBeUpdated,
  addSelectedDate,
  addSelectedSlot,
  clearSelectedSlot,
  addSelectedAttendees,
  clearSelectedAttendees,
  addRoomStatus,
  addAdditionSelectedAttendees,
  clearAdditionalSelectedAttendees,
  addFinalMultipleSlotsForAdmin,
  clearFinalMultipleSlotsForAdmin
} = coworkSlice.actions;
export const coworkData = (state) => state.coworkData;
export default coworkSlice;
