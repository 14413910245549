import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authentication/authSlice";
import coworkSlice from "./cowork/coworkSlice";
import userManagingSlice from "./userManagement/userManagingSlice";

export const store = configureStore({
  reducer: {
    authentication: authSlice.reducer,
    coworkData:coworkSlice.reducer,
    management: userManagingSlice.reducer,
  },
});
