import Cookies from "js-cookie";
import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";

export const PrivateRoute = () => {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  let token = Cookies.get("token");

  return token ? <Outlet /> : <Navigate to="/login" />;
};
