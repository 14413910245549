import React from "react";

// DashboardIcon component
const DashboardIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00004 14.6667H10C13.3334 14.6667 14.6667 13.3333 14.6667 10V6C14.6667 2.66667 13.3334 1.33333 10 1.33333H6.00004C2.66671 1.33333 1.33337 2.66667 1.33337 6V10C1.33337 13.3333 2.66671 14.6667 6.00004 14.6667Z"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M4.58667 12.1V10.72" stroke="#A3A3A3" stroke-linecap="round" />
    <path d="M8 12.1V9.33997" stroke="#A3A3A3" stroke-linecap="round" />
    <path d="M11.4133 12.1V7.95337" stroke="#A3A3A3" stroke-linecap="round" />
    <path
      d="M11.4133 3.90002L11.1067 4.26002C9.40667 6.24669 7.12667 7.65336 4.58667 8.28669"
      stroke="#A3A3A3"
      stroke-linecap="round"
    />
    <path
      d="M9.45996 3.90002H11.4133V5.84669"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

// UserManagementIcon component
const UserManagementIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.10671 7.24667C6.04004 7.24 5.96004 7.24 5.88671 7.24667C4.30004 7.19334 3.04004 5.89334 3.04004 4.29334C3.04004 2.66 4.36004 1.33334 6.00004 1.33334C7.63337 1.33334 8.96004 2.66 8.96004 4.29334C8.95337 5.89334 7.69337 7.19334 6.10671 7.24667Z"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.9401 2.66666C12.2334 2.66666 13.2734 3.71333 13.2734 5C13.2734 6.26 12.2734 7.28666 11.0267 7.33333C10.9734 7.32666 10.9134 7.32666 10.8534 7.33333"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.77335 9.70666C1.16002 10.7867 1.16002 12.5467 2.77335 13.62C4.60669 14.8467 7.61335 14.8467 9.44669 13.62C11.06 12.54 11.06 10.78 9.44669 9.70666C7.62002 8.48666 4.61335 8.48666 2.77335 9.70666Z"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.2267 13.3333C12.7067 13.2333 13.16 13.04 13.5334 12.7533C14.5734 11.9733 14.5734 10.6867 13.5334 9.90667C13.1667 9.62667 12.72 9.44 12.2467 9.33334"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

// TemplateManagementIcon component
const TemplateManagementIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2133 8C13.9467 8 14.6667 7.33333 14.0267 5.14667C13.5933 3.67333 12.3267 2.40667 10.8533 1.97333C8.66667 1.33333 8 2.05333 8 3.78667V5.70667C8 7.33333 8.66667 8 10 8H12.2133Z"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3333 9.8C12.7133 12.8867 9.75333 15.1267 6.38666 14.58C3.85999 14.1733 1.82666 12.14 1.41333 9.61333C0.873327 6.26 3.09999 3.3 6.17333 2.67333"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

// InterviewDashboardIcon component
const InterviewDashboardIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1066 13.2466C12.04 13.24 11.96 13.24 11.8866 13.2466C10.3 13.1933 9.03998 11.8933 9.03998 10.2933C9.03998 8.65998 10.36 7.33331 12 7.33331C13.6333 7.33331 14.96 8.65998 14.96 10.2933C14.9533 11.8933 13.6933 13.1933 12.1066 13.2466Z"
      stroke="#0052CC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.94 8.66669C18.2333 8.66669 19.2733 9.71335 19.2733 11C19.2733 12.26 18.2733 13.2867 17.0267 13.3334C16.9733 13.3267 16.9133 13.3267 16.8533 13.3334"
      stroke="#0052CC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.77335 15.7067C7.16002 16.7867 7.16002 18.5467 8.77335 19.62C10.6067 20.8467 13.6134 20.8467 15.4467 19.62C17.06 18.54 17.06 16.78 15.4467 15.7067C13.62 14.4867 10.6134 14.4867 8.77335 15.7067Z"
      stroke="#0052CC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.2267 19.3333C18.7067 19.2333 19.16 19.04 19.5334 18.7533C20.5734 17.9733 20.5734 16.6866 19.5334 15.9066C19.1667 15.6266 18.72 15.44 18.2467 15.3333"
      stroke="#0052CC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

// InterviewCallRecordIcon component
const InterviewCallRecordIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1066 13.2466C12.04 13.24 11.96 13.24 11.8866 13.2466C10.3 13.1933 9.03998 11.8933 9.03998 10.2933C9.03998 8.65998 10.36 7.33331 12 7.33331C13.6333 7.33331 14.96 8.65998 14.96 10.2933C14.9533 11.8933 13.6933 13.1933 12.1066 13.2466Z"
      stroke="#0052CC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.94 8.66669C18.2333 8.66669 19.2733 9.71335 19.2733 11C19.2733 12.26 18.2733 13.2867 17.0267 13.3334C16.9733 13.3267 16.9133 13.3267 16.8533 13.3334"
      stroke="#0052CC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.77335 15.7067C7.16002 16.7867 7.16002 18.5467 8.77335 19.62C10.6067 20.8467 13.6134 20.8467 15.4467 19.62C17.06 18.54 17.06 16.78 15.4467 15.7067C13.62 14.4867 10.6134 14.4867 8.77335 15.7067Z"
      stroke="#0052CC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.2267 19.3333C18.7067 19.2333 19.16 19.04 19.5334 18.7533C20.5734 17.9733 20.5734 16.6866 19.5334 15.9066C19.1667 15.6266 18.72 15.44 18.2467 15.3333"
      stroke="#0052CC"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

// Settings Icon
const SettingsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
      stroke="#A3A3A3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.33337 8.58667V7.41333C1.33337 6.72 1.90004 6.14667 2.60004 6.14667C3.80671 6.14667 4.30004 5.29333 3.69337 4.24667C3.34671 3.64667 3.55337 2.86667 4.16004 2.52L5.31337 1.86C5.84004 1.54666 6.52004 1.73333 6.83337 2.26L6.90671 2.38666C7.50671 3.43333 8.49337 3.43333 9.10004 2.38666L9.17337 2.26C9.48671 1.73333 10.1667 1.54666 10.6934 1.86L11.8467 2.52C12.4534 2.86667 12.66 3.64667 12.3134 4.24667C11.7067 5.29333 12.2 6.14667 13.4067 6.14667C14.1 6.14667 14.6734 6.71333 14.6734 7.41333V8.58667C14.6734 9.28 14.1067 9.85333 13.4067 9.85333C12.2 9.85333 11.7067 10.7067 12.3134 11.7533C12.66 12.36 12.4534 13.1333 11.8467 13.48L10.6934 14.14C10.1667 14.4533 9.48671 14.2667 9.17337 13.74L9.10004 13.6133C8.50004 12.5667 7.51337 12.5667 6.90671 13.6133L6.83337 13.74C6.52004 14.2667 5.84004 14.4533 5.31337 14.14L4.16004 13.48C3.55337 13.1333 3.34671 12.3533 3.69337 11.7533C4.30004 10.7067 3.80671 9.85333 2.60004 9.85333C1.90004 9.85333 1.33337 9.28 1.33337 8.58667Z"
      stroke="#A3A3A3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const EventIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99992 14.6666H9.99992C13.3333 14.6666 14.6666 13.3333 14.6666 9.99992V5.99992C14.6666 2.66659 13.3333 1.33325 9.99992 1.33325H5.99992C2.66659 1.33325 1.33325 2.66659 1.33325 5.99992V9.99992C1.33325 13.3333 2.66659 14.6666 5.99992 14.6666Z"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6667 11.3867H10.4333"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.64658 11.3867H4.33325"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.6666 8.87988H7.97998"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.17992 8.87988H4.33325"
      stroke="#A3A3A3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const AdminBooking = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33333 3.83337C5.05999 3.83337 4.83333 3.60671 4.83333 3.33337V1.33337C4.83333 1.06004 5.05999 0.833374 5.33333 0.833374C5.60666 0.833374 5.83333 1.06004 5.83333 1.33337V3.33337C5.83333 3.60671 5.60666 3.83337 5.33333 3.83337Z"
      fill="#A3A3A3"
    />
    <path
      d="M10.6667 3.83337C10.3933 3.83337 10.1667 3.60671 10.1667 3.33337V1.33337C10.1667 1.06004 10.3933 0.833374 10.6667 0.833374C10.94 0.833374 11.1667 1.06004 11.1667 1.33337V3.33337C11.1667 3.60671 10.94 3.83337 10.6667 3.83337Z"
      fill="#A3A3A3"
    />
    <path
      d="M5.66667 9.66667C5.58 9.66667 5.49333 9.64668 5.41333 9.61335C5.32667 9.58001 5.26 9.53333 5.19333 9.47333C5.07333 9.34667 5 9.18 5 9C5 8.91334 5.02 8.82667 5.05333 8.74667C5.08667 8.66667 5.13333 8.59334 5.19333 8.52668C5.26 8.46668 5.32667 8.42 5.41333 8.38666C5.65333 8.28666 5.95333 8.34001 6.14 8.52668C6.26 8.65334 6.33333 8.82667 6.33333 9C6.33333 9.04 6.32667 9.08668 6.32 9.13335C6.31333 9.17335 6.3 9.21334 6.28 9.25334C6.26667 9.29334 6.24667 9.33334 6.22 9.37334C6.2 9.40667 6.16667 9.44 6.14 9.47333C6.01333 9.59333 5.84 9.66667 5.66667 9.66667Z"
      fill="#A3A3A3"
    />
    <path
      d="M7.99999 9.6667C7.91333 9.6667 7.82666 9.64671 7.74666 9.61338C7.65999 9.58005 7.59333 9.53337 7.52666 9.47337C7.40666 9.3467 7.33333 9.18004 7.33333 9.00004C7.33333 8.91337 7.35333 8.8267 7.38666 8.7467C7.42 8.6667 7.46666 8.59338 7.52666 8.52671C7.59333 8.46671 7.65999 8.42003 7.74666 8.3867C7.98666 8.28003 8.28666 8.34004 8.47333 8.52671C8.59333 8.65338 8.66666 8.8267 8.66666 9.00004C8.66666 9.04004 8.65999 9.08671 8.65333 9.13338C8.64666 9.17338 8.63333 9.21337 8.61333 9.25337C8.59999 9.29337 8.58 9.33337 8.55333 9.37337C8.53333 9.4067 8.49999 9.44003 8.47333 9.47337C8.34666 9.59337 8.17333 9.6667 7.99999 9.6667Z"
      fill="#A3A3A3"
    />
    <path
      d="M10.3333 9.6667C10.2467 9.6667 10.16 9.64671 10.08 9.61338C9.99334 9.58005 9.92667 9.53337 9.86001 9.47337C9.83334 9.44003 9.80667 9.4067 9.78 9.37337C9.75334 9.33337 9.73334 9.29337 9.72001 9.25337C9.70001 9.21337 9.68667 9.17338 9.68001 9.13338C9.67334 9.08671 9.66667 9.04004 9.66667 9.00004C9.66667 8.8267 9.74001 8.65338 9.86001 8.52671C9.92667 8.46671 9.99334 8.42003 10.08 8.3867C10.3267 8.28003 10.62 8.34004 10.8067 8.52671C10.9267 8.65338 11 8.8267 11 9.00004C11 9.04004 10.9933 9.08671 10.9867 9.13338C10.98 9.17338 10.9667 9.21337 10.9467 9.25337C10.9333 9.29337 10.9133 9.33337 10.8867 9.37337C10.8667 9.4067 10.8333 9.44003 10.8067 9.47337C10.68 9.59337 10.5067 9.6667 10.3333 9.6667Z"
      fill="#A3A3A3"
    />
    <path
      d="M5.66667 12C5.58 12 5.49333 11.9801 5.41333 11.9467C5.33333 11.9134 5.26 11.8667 5.19333 11.8067C5.07333 11.68 5 11.5067 5 11.3334C5 11.2467 5.02 11.16 5.05333 11.08C5.08667 10.9934 5.13333 10.92 5.19333 10.86C5.44 10.6134 5.89333 10.6134 6.14 10.86C6.26 10.9867 6.33333 11.16 6.33333 11.3334C6.33333 11.5067 6.26 11.68 6.14 11.8067C6.01333 11.9267 5.84 12 5.66667 12Z"
      fill="#A3A3A3"
    />
    <path
      d="M7.99999 12C7.82666 12 7.65333 11.9267 7.52666 11.8067C7.40666 11.68 7.33333 11.5067 7.33333 11.3334C7.33333 11.2467 7.35333 11.16 7.38666 11.08C7.42 10.9934 7.46666 10.92 7.52666 10.86C7.77333 10.6134 8.22666 10.6134 8.47333 10.86C8.53333 10.92 8.57999 10.9934 8.61333 11.08C8.64666 11.16 8.66666 11.2467 8.66666 11.3334C8.66666 11.5067 8.59333 11.68 8.47333 11.8067C8.34666 11.9267 8.17333 12 7.99999 12Z"
      fill="#A3A3A3"
    />
    <path
      d="M10.3333 12.0001C10.16 12.0001 9.98667 11.9267 9.86001 11.8067C9.80001 11.7467 9.75334 11.6734 9.72001 11.5867C9.68667 11.5067 9.66667 11.4201 9.66667 11.3334C9.66667 11.2467 9.68667 11.1601 9.72001 11.0801C9.75334 10.9934 9.80001 10.9201 9.86001 10.8601C10.0133 10.7067 10.2467 10.6334 10.46 10.6801C10.5067 10.6867 10.5467 10.7001 10.5867 10.7201C10.6267 10.7334 10.6667 10.7534 10.7067 10.7801C10.74 10.8001 10.7733 10.8334 10.8067 10.8601C10.9267 10.9867 11 11.1601 11 11.3334C11 11.5067 10.9267 11.6801 10.8067 11.8067C10.68 11.9267 10.5067 12.0001 10.3333 12.0001Z"
      fill="#A3A3A3"
    />
    <path
      d="M13.6667 6.55994H2.33333C2.05999 6.55994 1.83333 6.33327 1.83333 6.05994C1.83333 5.7866 2.05999 5.55994 2.33333 5.55994H13.6667C13.94 5.55994 14.1667 5.7866 14.1667 6.05994C14.1667 6.33327 13.94 6.55994 13.6667 6.55994Z"
      fill="#A3A3A3"
    />
    <path
      d="M10.6667 15.1667H5.33333C2.9 15.1667 1.5 13.7667 1.5 11.3334V5.66671C1.5 3.23337 2.9 1.83337 5.33333 1.83337H10.6667C13.1 1.83337 14.5 3.23337 14.5 5.66671V11.3334C14.5 13.7667 13.1 15.1667 10.6667 15.1667ZM5.33333 2.83337C3.42667 2.83337 2.5 3.76004 2.5 5.66671V11.3334C2.5 13.24 3.42667 14.1667 5.33333 14.1667H10.6667C12.5733 14.1667 13.5 13.24 13.5 11.3334V5.66671C13.5 3.76004 12.5733 2.83337 10.6667 2.83337H5.33333Z"
      fill="#A3A3A3"
    />
  </svg>
);
const MyBooking = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.33333 3.83337C5.05999 3.83337 4.83333 3.60671 4.83333 3.33337V1.33337C4.83333 1.06004 5.05999 0.833374 5.33333 0.833374C5.60666 0.833374 5.83333 1.06004 5.83333 1.33337V3.33337C5.83333 3.60671 5.60666 3.83337 5.33333 3.83337Z" fill="#A3A3A3"/>
<path d="M10.6667 3.83337C10.3933 3.83337 10.1667 3.60671 10.1667 3.33337V1.33337C10.1667 1.06004 10.3933 0.833374 10.6667 0.833374C10.94 0.833374 11.1667 1.06004 11.1667 1.33337V3.33337C11.1667 3.60671 10.94 3.83337 10.6667 3.83337Z" fill="#A3A3A3"/>
<path d="M5.66667 9.66667C5.49333 9.66667 5.32 9.59333 5.19333 9.47333C5.07333 9.34666 5 9.18 5 9C5 8.91334 5.02 8.82667 5.05333 8.74667C5.08667 8.66667 5.13333 8.59334 5.19333 8.52667C5.44 8.28001 5.88667 8.28001 6.14 8.52667C6.26 8.65334 6.33333 8.82667 6.33333 9C6.33333 9.04 6.32667 9.08668 6.32 9.13334C6.31334 9.17334 6.3 9.21334 6.28 9.25334C6.26667 9.29334 6.24667 9.33333 6.22 9.37333C6.19333 9.40667 6.16667 9.44 6.14 9.47333C6.01333 9.59333 5.84 9.66667 5.66667 9.66667Z" fill="#A3A3A3"/>
<path d="M7.99999 9.66667C7.91333 9.66667 7.82666 9.64668 7.74666 9.61335C7.66 9.58001 7.59333 9.53333 7.52666 9.47333C7.40666 9.34667 7.33333 9.18 7.33333 9C7.33333 8.91334 7.35333 8.82667 7.38666 8.74667C7.42 8.66667 7.46666 8.59334 7.52666 8.52668C7.59333 8.46668 7.66 8.42 7.74666 8.38666C7.99333 8.28666 8.28666 8.34001 8.47333 8.52668C8.59333 8.65334 8.66666 8.82667 8.66666 9C8.66666 9.04 8.66 9.08668 8.65333 9.13335C8.64666 9.17335 8.63333 9.21334 8.61333 9.25334C8.59999 9.29334 8.58 9.33334 8.55333 9.37334C8.53333 9.40667 8.49999 9.44 8.47333 9.47333C8.34666 9.59333 8.17333 9.66667 7.99999 9.66667Z" fill="#A3A3A3"/>
<path d="M5.66667 12C5.58 12 5.49334 11.98 5.41334 11.9467C5.32667 11.9134 5.25333 11.8667 5.19333 11.8067C5.13333 11.7467 5.08667 11.6734 5.05333 11.5867C5.02 11.5067 5 11.42 5 11.3334C5 11.2467 5.02 11.16 5.05333 11.08C5.08667 10.9933 5.13333 10.92 5.19333 10.86C5.25333 10.8 5.32667 10.7533 5.41334 10.72C5.57334 10.6533 5.76 10.6467 5.92 10.72C6.00666 10.7533 6.08 10.8 6.14 10.86C6.2 10.92 6.24667 10.9933 6.28 11.08C6.31333 11.16 6.33333 11.2467 6.33333 11.3334C6.33333 11.42 6.31333 11.5067 6.28 11.5867C6.24667 11.6734 6.2 11.7467 6.14 11.8067C6.08 11.8667 6.00666 11.9134 5.92 11.9467C5.84 11.98 5.75333 12 5.66667 12Z" fill="#A3A3A3"/>
<path d="M13.6667 6.55994H2.33333C2.05999 6.55994 1.83333 6.33327 1.83333 6.05994C1.83333 5.7866 2.05999 5.55994 2.33333 5.55994H13.6667C13.94 5.55994 14.1667 5.7866 14.1667 6.05994C14.1667 6.33327 13.94 6.55994 13.6667 6.55994Z" fill="#A3A3A3"/>
<path d="M12 15.8333C11.22 15.8333 10.48 15.5533 9.91333 15.04C9.67333 14.84 9.46 14.5867 9.28666 14.2933C8.99333 13.8133 8.83333 13.2467 8.83333 12.6667C8.83333 10.92 10.2533 9.5 12 9.5C12.9067 9.5 13.7733 9.89332 14.3733 10.5733C14.8867 11.16 15.1667 11.9 15.1667 12.6667C15.1667 13.2467 15.0067 13.8133 14.7067 14.3C14.1467 15.2467 13.1067 15.8333 12 15.8333ZM12 10.5C10.8067 10.5 9.83333 11.4733 9.83333 12.6667C9.83333 13.06 9.94 13.4467 10.1467 13.78C10.26 13.98 10.4067 14.1467 10.5667 14.2867C10.9667 14.6467 11.4667 14.8333 12 14.8333C12.7667 14.8333 13.46 14.44 13.8533 13.7867C14.06 13.4467 14.1667 13.0667 14.1667 12.6667C14.1667 12.1467 13.9733 11.64 13.6267 11.2333C13.2133 10.7667 12.62 10.5 12 10.5Z" fill="#A3A3A3"/>
<path d="M11.6199 13.8267C11.4933 13.8267 11.3666 13.78 11.2666 13.68L10.6066 13.02C10.4133 12.8267 10.4133 12.5067 10.6066 12.3134C10.7999 12.12 11.1199 12.12 11.3133 12.3134L11.6333 12.6334L12.6999 11.6467C12.8999 11.46 13.2199 11.4734 13.4066 11.6734C13.5933 11.8734 13.5799 12.1934 13.3799 12.38L11.9599 13.6934C11.8599 13.78 11.7399 13.8267 11.6199 13.8267Z" fill="#A3A3A3"/>
<path d="M10.2467 15.1667H5.33333C2.9 15.1667 1.5 13.7667 1.5 11.3334V5.66671C1.5 3.23337 2.9 1.83337 5.33333 1.83337H10.6667C13.1 1.83337 14.5 3.23337 14.5 5.66671V10.9067C14.5 11.1134 14.3733 11.3 14.1733 11.3734C13.98 11.4467 13.76 11.3934 13.62 11.2334C13.2067 10.7667 12.6133 10.5 11.9933 10.5C10.8 10.5 9.82667 11.4734 9.82667 12.6667C9.82667 13.06 9.93333 13.4467 10.14 13.7801C10.2533 13.9801 10.4 14.1467 10.56 14.2867C10.72 14.42 10.78 14.64 10.7067 14.84C10.6467 15.0334 10.46 15.1667 10.2467 15.1667ZM5.33333 2.83337C3.42667 2.83337 2.5 3.76004 2.5 5.66671V11.3334C2.5 13.24 3.42667 14.1667 5.33333 14.1667H9.21333C8.96667 13.7134 8.83333 13.2 8.83333 12.6667C8.83333 10.92 10.2533 9.50004 12 9.50004C12.5267 9.50004 13.0467 9.63338 13.5 9.88005V5.66671C13.5 3.76004 12.5733 2.83337 10.6667 2.83337H5.33333Z" fill="#A3A3A3"/>
</svg>

  
);
const AllRoom = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.61334 1.50668L12.9533 3.84668C13.46 4.12002 13.46 4.90002 12.9533 5.17335L8.61334 7.51335C8.22667 7.72002 7.77334 7.72002 7.38667 7.51335L3.04667 5.17335C2.54001 4.90002 2.54001 4.12002 3.04667 3.84668L7.38667 1.50668C7.77334 1.30002 8.22667 1.30002 8.61334 1.50668Z" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.40666 6.75335L6.43999 8.77335C6.93999 9.02668 7.25999 9.54001 7.25999 10.1V13.9133C7.25999 14.4667 6.67999 14.82 6.18666 14.5733L2.15333 12.5533C1.65333 12.3 1.33333 11.7867 1.33333 11.2267V7.41335C1.33333 6.86001 1.91333 6.50668 2.40666 6.75335Z" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M13.5933 6.75335L9.56001 8.77335C9.06001 9.02668 8.74001 9.54001 8.74001 10.1V13.9133C8.74001 14.4667 9.32001 14.82 9.81334 14.5733L13.8467 12.5533C14.3467 12.3 14.6667 11.7867 14.6667 11.2267V7.41335C14.6667 6.86001 14.0867 6.50668 13.5933 6.75335Z" stroke="#A3A3A3" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  
);
const AllBookings = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3333 15.1666H4.66666C1.72666 15.1666 0.833328 14.2733 0.833328 11.3333V4.66665C0.833328 1.72665 1.72666 0.833313 4.66666 0.833313H5.66666C6.83333 0.833313 7.2 1.21331 7.66666 1.83331L8.66666 3.16665C8.88666 3.45998 8.92 3.49998 9.33333 3.49998H11.3333C14.2733 3.49998 15.1667 4.39331 15.1667 7.33331V11.3333C15.1667 14.2733 14.2733 15.1666 11.3333 15.1666ZM4.66666 1.83331C2.27999 1.83331 1.83333 2.28665 1.83333 4.66665V11.3333C1.83333 13.7133 2.27999 14.1666 4.66666 14.1666H11.3333C13.72 14.1666 14.1667 13.7133 14.1667 11.3333V7.33331C14.1667 4.95331 13.72 4.49998 11.3333 4.49998H9.33333C8.48 4.49998 8.2 4.20665 7.86666 3.76665L6.86666 2.43331C6.52 1.97331 6.41333 1.83331 5.66666 1.83331H4.66666Z"
      fill="#A3A3A3"
    />
    <path
      d="M13.3333 4.75331C13.06 4.75331 12.8333 4.52665 12.8333 4.25331V3.33331C12.8333 2.27998 12.3867 1.83331 11.3333 1.83331H5.33333C5.05999 1.83331 4.83333 1.60665 4.83333 1.33331C4.83333 1.05998 5.05999 0.833313 5.33333 0.833313H11.3333C12.9467 0.833313 13.8333 1.71998 13.8333 3.33331V4.25331C13.8333 4.52665 13.6067 4.75331 13.3333 4.75331Z"
      fill="#A3A3A3"
    />
  </svg>
);

export {
  DashboardIcon,
  UserManagementIcon,
  TemplateManagementIcon,
  InterviewDashboardIcon,
  InterviewCallRecordIcon,
  SettingsIcon,
  EventIcon,
  AdminBooking,
  MyBooking,
  AllBookings,
  AllRoom,
};
