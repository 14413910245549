import React from "react";
import mainlogo from "../../assets/img/mainlogo.svg";
import Cemail from "../../assets/svg/Cemail.svg";
import Pcall from "../../assets/svg/Pcall.svg";
import location from "../../assets/svg/location.svg";

import "../../Pages/Contact/contact.css";
import './styles.css'
import { useNavigate } from "react-router-dom";

function Privacy() {
	const navigate = useNavigate();
	return (
		<main className="contactUsWrapper">
			<div
				className="headerlogo"
				onClick={() => navigate("/")}
			>
				<img
					src={mainlogo}
					alt=""
				/>
			</div>



			<div className="privacyContainer">
				<h3>Privacy Policy</h3>
				<p>
					Your privacy is important to us. It is CoWork’s policy to respect your privacy regarding any information we may collect from you across our website, https://cowork.mastersunion.org/, and other sites we own and operate.
				</p>
				<h3>Information Collection and Use</h3>
				<p>We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.</p>
				<h3>Data Retention</h3>
				<p>We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
				<h3>Communication</h3>
				<p>When you voluntarily send us electronic mail, we will keep a record of this information so that we can respond to you. We only collect information from you when you register on our site or fill out a form. Also, when filling out a form on our site, you may be asked to enter your: name, e-mail address, or phone number. You may, however, visit our site anonymously. In case you have submitted your personal information and contact details, we reserve the right to Call, SMS, Email, or WhatsApp about our products and offers, even if your number has DND activated on it.</p>
				<h3>Information Sharing</h3>
				<p>We don’t share any personally identifying information publicly or with third-parties, except when required to by law.
				</p>
				<h3>External Links</h3>
				<p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.</p>

				<h3>Your Choices</h3>
				<p>You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.</p>

				<h3>Acceptance of Terms</h3>
				<p>Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</p>

				<h3>Effective Date</h3>
				<p>This policy is effective as of 1 September 2023.</p>
			</div>

		</main>
	);
}

export default Privacy;
